/* @tailwind base; */
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  @apply m-0 bg-white2;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  80%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  80%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  80%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
.talkingPointsBg {
  background: linear-gradient(270deg, #d1fae5 -74.53%, #fff 72.31%), #fff;
}

.sequenceCard:hover {
  border: 1px solid #4f46e5 !important;
}
.sequenceCard:hover .viewOnHover {
  display: flex !important;
}

.viewOnHover {
  display: none !important;
}

.self-start .ant-checkbox {
  align-self: flex-start;
}

.ant-input.ant-input-lg.ant-input-status-error {
  @apply border-brightRed !important;
}

.ant-input-affix-wrapper-status-error {
  @apply border-brightRed !important;
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  visibility: visible;
}

.insightsTab > .ant-tabs-nav {
  @apply sticky top-0 z-[1] bg-white2;
}
.insightsTab > .ant-tabs-nav::before {
  @apply border-gray-200;
}
.insightsTab > .ant-tabs-nav::after {
  @apply absolute bg-white2;
  content: '';
  inset: 0 -20px;
  z-index: -1;
}
.insights-container.ant-tabs .ant-tabs-tab {
  margin: 0 0 0 16px !important;
}
.insights-container.ant-tabs .ant-tabs-tab:nth-child(1) {
  margin: 0 !important;
}
.insightsTab .ant-tabs-nav .ant-tabs-nav-list {
  @apply w-11/12;
}

.insightsTab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  @apply mr-0 w-full text-center justify-center grow;
}
.insightsTab .ant-tabs-content .ant-tabs-nav::before {
  @apply border-b-0;
}
.insightsTab
  .ant-tabs-content
  .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-ink-bar {
  @apply hidden;
}
body,
html {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  @apply hidden;
}
.ant-form-item .ant-form-item-explain-error {
  @apply hidden;
}
.tour-dropdown {
  z-index: 900 !important;
}
.ant-modal-root .ant-modal-mask,
.ant-modal-root .ant-modal-wrap {
  z-index: 1031 !important;
}
.refreshTour.ant-tour {
  width: 80%;
}
.refreshTour.ant-tour .ant-tour-inner,
.refreshTour.ant-tour .ant-tour-arrow:before {
  @apply bg-textPrimary text-white;
}
.refreshTour.ant-tour .anticon-close.ant-tour-close {
  @apply hidden;
}
.refreshTour.ant-tour .ant-tour-footer {
  @apply flex items-center;
}
.refreshTour.ant-tour-mask .ant-tour-placeholder-animated {
  rx: 8px;
}
.refreshTour.ant-tour .ant-tour-content {
  @apply -right-1;
  width: 300px !important;
}
