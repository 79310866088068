.tabClassname .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  @apply hidden;
}

.tabClassname .ant-tabs-content-holder {
  @apply border-0;
}

.tabClassname .ant-tabs-tab.ant-tabs-tab-active {
  @apply bg-primaryActive rounded;
}

.tabClassname .ant-tabs-content.ant-tabs-content-left {
  @apply pl-12;
}
