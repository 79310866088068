
.type-sub-heading {
  @apply xl:text-base lg:text-sm text-slate-500 font-semibold;
}
.type-pxs {
  @apply text-xs font-extralight font-inter leading-normal;
}
.type-psm {
  @apply text-sm font-normal text-slate-500 font-inter leading-normal;
}
.type-plg {
  @apply text-lg font-inter leading-normal;
}
.type-p {
  @apply text-base font-inter leading-normal;
}

.type-h1 {
  @apply text-xl lg:text-base font-inter leading-normal font-normal;
}
.type-h2 {
  @apply text-2xl font-inter leading-normal font-normal;
}
.type-h3 {
  @apply text-3xl font-inter leading-normal font-medium;
}
.type-h4 {
  @apply text-4xl font-inter leading-normal font-semibold;
}
.type-h5 {
  @apply text-5xl lg:text-4xl font-inter leading-normal font-bold;
}
.type-h6 {
  @apply text-6xl lg:text-6xl font-inter leading-normal font-extrabold;
}
.type-span {
  @apply text-sm font-light font-inter leading-normal;
}
.type-title {
  @apply text-3xl lg:text-2xl font-inter leading-normal font-medium mt-24 mb-4;
}
.type-button {
  @apply text-base lg:text-sm font-normal p-1 px-4 rounded-3xl !bg-primary font-inter leading-normal;
}
.text-navlink {
  @apply text-sm lg:text-xs font-bold font-inter leading-normal;
}
